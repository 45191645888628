export var getInitials = function (name) {
    // Check if the name is provided and not empty
    if (!name || name.trim().length === 0) {
        return ''; // or return a placeholder like 'N/A'
    }
    return name
        .split(' ')
        .map(function (part) { return part[0]; })
        .join('')
        .toUpperCase();
};
export function formatDate(dateString) {
    var date = new Date(dateString);
    var month = date.toLocaleString('en-US', { month: 'short' });
    var day = String(date.getDate()).padStart(2, '0');
    var year = date.getFullYear();
    return "".concat(month, ", ").concat(day, ", ").concat(year);
}
// Compare Date
export function isDateInFuture(givenDateString) {
    var givenDate = new Date(givenDateString);
    var today = new Date();
    // Reset the time portion to compare only the date part
    today.setHours(0, 0, 0, 0);
    givenDate.setHours(0, 0, 0, 0);
    return givenDate > today;
}
export var newFormatDate = function (dateString) {
    var date = new Date(dateString);
    var options = {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };
    return date.toLocaleString('en-US', options);
};
export var extractMp4Url = function (url) {
    return url.includes(".mp4") ? url.substring(0, url.indexOf(".mp4") + 4) : url;
};
