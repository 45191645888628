// ReplicaSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { createVideo, getCurrentUserVideos } from "./createVideoThunk";
var initialState = {
    loading: false,
    error: null,
};
var createVideoSlice = createSlice({
    name: "createVideo",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(createVideo.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(createVideo.fulfilled, function (state, action) {
            state.loading = false;
            state.error = 'fasle';
        })
            .addCase(createVideo.rejected, function (state, action) {
            state.loading = false;
            state.error = action.payload;
        })
            .addCase(getCurrentUserVideos.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(getCurrentUserVideos.fulfilled, function (state, action) {
            state.loading = false;
            state.error = 'fasle';
        })
            .addCase(getCurrentUserVideos.rejected, function (state, action) {
            state.loading = false;
            state.error = action.payload;
        });
    },
});
export default createVideoSlice.reducer;
