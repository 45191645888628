var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from "react";
import { Nav } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faVideo, faFileVideo, faTimes, faUserGroup, faUsers } from "@fortawesome/free-solid-svg-icons";
import User from "assets/images/dummy-user.png";
import { getUserData } from "utils/storage";
import { useAppSelector } from "hooks";
var Sidebar = function (_a) {
    var isOpen = _a.isOpen, toggleSidebar = _a.toggleSidebar;
    var currentPath = useLocation().pathname;
    var navigate = useNavigate();
    var user = useMemo(function () { return getUserData(); }, []); // Memoize user data to prevent re-render loops
    var _b = useState([]), navLinks = _b[0], setNavLinks = _b[1];
    var _c = useState(null), currentSubscriptionData = _c[0], setCurrentSubscriptionData = _c[1];
    var currentSubscription = useAppSelector(function (state) { return state.subscription; }).currentSubscription;
    useEffect(function () {
        setCurrentSubscriptionData(currentSubscription);
    }, [currentSubscription]);
    var activeIndex = useMemo(function () {
        return navLinks.findIndex(function (link) { return currentPath.startsWith(link.url); });
    }, [currentPath, navLinks]);
    useEffect(function () {
        if ((user === null || user === void 0 ? void 0 : user.userType) === "admin") {
            setNavLinks([
                {
                    url: "/user-management",
                    label: "User Management",
                    icon: faUserGroup,
                },
            ]);
        }
        else {
            if (currentSubscriptionData) {
                setNavLinks([
                    { url: "/dashboard", label: "Home", icon: faHome },
                    {
                        url: "/create-video",
                        label: "Create Video",
                        icon: faVideo,
                        border: true,
                    },
                    { url: "/my-videos", label: "My Videos", icon: faFileVideo },
                    {
                        url: "/create-replica",
                        label: "Create Replica",
                        icon: faUserGroup,
                        border: true,
                    },
                    { url: "/replica-library", label: "Replica Library", icon: faUsers },
                ]);
            }
            else {
                setNavLinks([{ url: "/subscription", label: "Home", icon: faHome }]);
            }
        }
    }, [user === null || user === void 0 ? void 0 : user.userType, currentSubscriptionData]); // Dependency array should only include user.userType
    return (_jsxs("div", __assign({ className: "sidebar ".concat(isOpen ? "open" : "") }, { children: [_jsx("div", __assign({ className: "sidebar-header d-lg-none" }, { children: _jsx(FontAwesomeIcon, { icon: faTimes, className: "close-icon", onClick: toggleSidebar }) })), _jsxs("div", __assign({ className: "profile-section d-flex align-items-center justify-content-center" }, { children: [_jsx("img", { src: User, alt: "Profile", className: "profile-image me-2" }), _jsxs("div", __assign({ className: "mt-3" }, { children: [_jsx("h4", __assign({ className: "dashboard mb-1" }, { children: "MY DASHBOARD" })), (user === null || user === void 0 ? void 0 : user.fullName) && (_jsx("h3", __assign({ className: "profile-name mt-2" }, { children: user.fullName })))] }))] })), _jsx(Nav, __assign({ className: "flex-column" }, { children: navLinks.map(function (link, index) { return (_jsxs(React.Fragment, { children: [link.border && _jsx("div", { className: "divider-section mt-3 mb-2" }), _jsxs(Nav.Link, __assign({ className: "nav-link ".concat(activeIndex === index ? "active" : ""), onClick: function () { navigate(link.url); toggleSidebar(); } }, { children: [link.icon && (_jsx(FontAwesomeIcon, { icon: link.icon, className: "me-2", style: { fontSize: 19 } })), link.label] }), link.url)] }, index)); }) })), (user === null || user === void 0 ? void 0 : user.userType) === "user" && currentSubscriptionData && (_jsx("button", __assign({ className: "upgrade-button mt-auto", onClick: function () { return navigate("/subscription"); } }, { children: "Upgrade Plan" })))] })));
};
export default React.memo(Sidebar);
