// ReplicaSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { uploadReplica } from "./replicaThunk";
var initialState = {
    loading: false,
    error: null,
};
var subscriptionSlice = createSlice({
    name: "replica",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(uploadReplica.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(uploadReplica.fulfilled, function (state, action) {
            state.loading = false;
        })
            .addCase(uploadReplica.rejected, function (state, action) {
            state.loading = false;
            state.error = action.payload;
        });
    },
});
export default subscriptionSlice.reducer;
