// src/app/rootReducer.ts
import { combineReducers } from '@reduxjs/toolkit';
import auth from 'features/auth/authSlice';
import subscription from 'features/subscription/subscriptionSlice';
import user from 'features/user/userSlice';
import replica from 'features/replica/replicaSlice';
import createVideo from 'features/createVideo/createVideoSlice';
var rootReducer = combineReducers({
    auth: auth,
    subscription: subscription,
    user: user,
    replica: replica,
    createVideo: createVideo
});
export default rootReducer;
