var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
// src/components/Typography
import styled from 'styled-components';
var Typography = styled.div.attrs(function (props) { return ({
    as: props.variant || 'p',
}); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  background: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: ", ";\n  margin: ", ";\n  font-family: 'Morrison', sans-serif;\n  line-height: ", ";\n\n  @media (max-width: 600px) {\n   font-size: ", ";\n   text-align: ", ";\n  }\n"], ["\n  color: ", ";\n  background: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: ", ";\n  margin: ", ";\n  font-family: 'Morrison', sans-serif;\n  line-height: ", ";\n\n  @media (max-width: 600px) {\n   font-size: ", ";\n   text-align: ", ";\n  }\n"])), function (props) { return (props === null || props === void 0 ? void 0 : props.color) ? "var(--".concat(props.color, ")") : 'var(--primary-text-color)'; }, function (props) { return (props === null || props === void 0 ? void 0 : props.background) && "var(--".concat(props.background, ")"); }, function (props) { return props.size ? "".concat(props.size, "px") : 'var(--default-font-size)'; }, function (props) { return props.weight || 400; }, function (props) { return props.align || 'left'; }, function (props) { return props.margin || '0'; }, function (props) { return props.lineHeight || ''; }, function (props) { return props.mobilesize ? "".concat(props.mobilesize, "px") : (props === null || props === void 0 ? void 0 : props.variant) === 'h1' ? '24px' : (props === null || props === void 0 ? void 0 : props.variant) === 'h2' && '22px'; }, function (props) { return props.mobilealign || props.align; });
export default Typography;
var templateObject_1;
