import { io } from 'socket.io-client';
var socket = null;
var connectSocket = function () {
    if (!socket) {
        socket = io('https://api.vtwin.develop.invo.zone', {
        // path: '/ws', // Explicitly specify root path/
        // Optional: Set additional options
        // reconnectionAttempts: 5, // Reconnect attempts on error
        // timeout: 10000, // Connection timeout
        });
        socket.on('connect', function () {
            console.log('Socket connected!');
        });
        socket.on('connect_error', function (error) {
            console.error('Connection error:', error);
        });
        socket.on('disconnect', function (reason) {
            console.log('Disconnected:', reason);
        });
        socket.on('error', function (err) {
            console.error('Socket error:', err);
        });
    }
    return socket;
};
export default { connectSocket: connectSocket, socket: socket };
